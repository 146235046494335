<template>
  <div class="container">
    <Breadcrumb>
      <template slot="lastPage">
        {{ title }}
      </template>
    </Breadcrumb>
    <el-divider />
    <h1>{{ title }}</h1>
    <CounselingsState
      v-if="user"
      :isEnrolledCounseling="false"
      :studentUserId="user.basic_info.id"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import CounselingsState from "@/views/private/CounselingsState/Index";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: `${this.title} - Ivy-Way Academy`
    };
  },
  watch: {},
  mixins: [],
  components: {
    Breadcrumb,
    CounselingsState
  },
  computed: {
    title() {
      return "Counseling";
    }
  },
  data() {
    return {
      user: null
    };
  },
  async created() {
    const userName =
      this.$route.params.username ||
      this.$store.getters["user/getProfile"].username;
    const user = await profileApi.getUserByName(userName);
    this.user = user;
  },
  methods: {}
};
</script>
