import ApiService from "@/common/api.service";

export default {
  getThirdApplicationEssayPrompts(show_doc_by_user_id) {
    return ApiService.query("essay-prompts", {
      show_doc_by_user_id
    });
  },
  getThirdApplicationEssayPromptContent(studentEssayId) {
    return ApiService.get(`google-doc-content/${studentEssayId}`);
  },
  createApplicationEssayDocument(documentType, studentUserId, essayPromptId) {
    const [GOOGLE, WPS] = [1, 2];
    switch (documentType) {
      case GOOGLE:
        return ApiService.post("new-essay-google-doc", {
          student_user_id: studentUserId,
          essay_prompt_id: essayPromptId
        });
      case WPS:
        console.log("wps");
        break;
    }
  },
  updateStudentEssay(studentEssayId, data) {
    return ApiService.post(`student-essay/${studentEssayId}`, data);
  }
};
