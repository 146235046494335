<template>
  <div class="stateCard">
    <div class="mask" />
    <div class="title" :style="`background: ${titleBackgroundColor}`">
      {{ title }}
    </div>
    <div class="content" :style="`background: ${contentBackgroundColor}`">
      <div><i :class="icon" /></div>
      <div class="state">
        {{ state }}
        <div class="stateText">{{ stateText }}</div>
      </div>
    </div>
    <div class="footer" :style="`background: ${footerBackgroundColor}`">
      {{ footerText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    state: {
      type: String,
      default: ""
    },
    stateText: {
      type: String,
      default: ""
    },
    footerText: {
      type: String,
      default: ""
    },
    titleBackgroundColor: {
      type: String,
      default: ""
    },
    contentBackgroundColor: {
      type: String,
      default: ""
    },
    footerBackgroundColor: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.stateCard {
  display: grid;
  grid-template-rows: 46px 1fr 40px;
  cursor: pointer;
  min-width: 260px;
  color: #ffffff;
  height: 180px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.mask {
  background: transparent;
  position: absolute;
  min-width: inherit;
  height: inherit;
  border-radius: 4px;
}

.mask:hover {
  transition: background 0.3s;
  background: #00000022;
}

.stateCard > div {
  padding: 8px 16px;
}

.title {
  font-size: 20px;
  border-radius: 4px 4px 0px 0px;
}

.content {
  display: flex;
  justify-content: space-between;
  font-size: 40px;
}

.state {
  text-align: right;
}

.stateText {
  font-size: 12px;
}

.footer {
  border-radius: 0px 0px 4px 4px;
}
</style>
