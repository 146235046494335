<template>
  <div class="flexWrap">
    <template v-if="isEnrolledCounseling && counselingId">
      <router-link
        :to="{ name: 'counselingInfo', params: { id: counselingId } }"
      >
        <Card
          title="PACKAGE TYPE"
          icon="fas fa-user"
          state="VIP"
          footerText="View details"
          titleBackgroundColor="#eb5e82"
          contentBackgroundColor="#e73c68"
          footerBackgroundColor="#d61a4b"
        />
      </router-link>
      <router-link
        :to="{ name: 'counselingSchool', params: { id: counselingId } }"
      >
        <Card
          title="COLLEGES"
          icon="fas fa-building"
          :state="String(collectedCounselingSchools.length)"
          stateText="colleges on my list"
          footerText="View my college list"
          titleBackgroundColor="#1bc2a1"
          contentBackgroundColor="#16a085"
          footerBackgroundColor="#107360"
        />
      </router-link>
      <router-link
        :to="{ name: 'counselingEssays', params: { id: counselingId } }"
      >
        <Card
          title="ESSAYS"
          icon="far fa-clone"
          :state="`${completedEssayCount} / ${essayPromptCount}`"
          stateText="essays written"
          footerText="View my essays"
          titleBackgroundColor="#98d3f1"
          contentBackgroundColor="#76c4ed"
          footerBackgroundColor="#49b1e7"
        />
      </router-link>
      <router-link
        :to="{ name: 'counselingMeetingLogs', params: { id: counselingId } }"
      >
        <Card
          title="MEETINGS"
          icon="far fa-comments"
          :state="String(meetHour)"
          :stateText="`hours (${meets.length} meetings)`"
          footerText="View meeting logs"
          titleBackgroundColor="#a059be"
          contentBackgroundColor="#8e44ad"
          footerBackgroundColor="#703688"
        />
      </router-link>
      <router-link
        :to="{ name: 'counselingMeetingReports', params: { id: counselingId } }"
      >
        <Card
          title="MONTHLY REPORTS"
          icon="fas fa-calendar-alt"
          :state="String(reports.length)"
          stateText="reports"
          footerText="View reports"
          titleBackgroundColor="#f5ab36"
          contentBackgroundColor="#f39c12"
          footerBackgroundColor="#c87f0a"
        />
      </router-link>
      <router-link
        :to="{ name: 'counselingThirdApp', params: { id: counselingId } }"
      >
        <Card
          title="APPLICATION FORMS"
          icon="fas fa-people-arrows"
          :state="String(applicationCount)"
          stateText=""
          footerText="View my applications"
          titleBackgroundColor="#ffc7dd"
          contentBackgroundColor="#F5ABC9"
          footerBackgroundColor="#ff7db0"
        />
      </router-link>
      <router-link
        :to="{ name: 'counselingTasks', params: { id: counselingId } }"
      >
        <Card
          title="TASKS"
          icon="fas fa-list"
          stateText=""
          footerText="View my tasks"
          titleBackgroundColor="#417D7A"
          contentBackgroundColor="#1D5C63"
          footerBackgroundColor="#1A3C40"
        />
      </router-link>
    </template>
    <template v-else>
      <div @click="openEnrollInfoDailog">
        <Card
          title="PACKAGE TYPE"
          icon="fas fa-user"
          state="Free"
          footerText="."
          titleBackgroundColor="#eb5e82"
          contentBackgroundColor="#e73c68"
          footerBackgroundColor="#d61a4b"
        />
      </div>
      <router-link
        :to="{
          name: 'counselingUserSchool',
          params: { username: user.basic_info.username }
        }"
      >
        <Card
          title="COLLEGES"
          icon="fas fa-building"
          :state="String(collectedCounselingSchools.length)"
          stateText="colleges on my list"
          footerText="View my college list"
          titleBackgroundColor="#1bc2a1"
          contentBackgroundColor="#16a085"
          footerBackgroundColor="#107360"
        />
      </router-link>
      <router-link
        :to="{
          name: 'counselingUserEssays',
          params: { username: user.basic_info.username }
        }"
      >
        <Card
          title="ESSAYS"
          icon="far fa-clone"
          :state="`${completedEssayCount} / ${essayPromptCount}`"
          stateText="essays written"
          footerText="View my essays"
          titleBackgroundColor="#98d3f1"
          contentBackgroundColor="#76c4ed"
          footerBackgroundColor="#49b1e7"
        />
      </router-link>
      <div class="blockWrap" @click="openEnrollInfoDailog">
        <span class="lockIcon"><i class="fas fa-lock" /></span>
        <Card
          title="MEETINGS"
          icon="far fa-comments"
          :state="String(meetHour)"
          :stateText="`hours (${meets.length} meetings)`"
          footerText="View meeting logs"
          titleBackgroundColor="#a059be"
          contentBackgroundColor="#8e44ad"
          footerBackgroundColor="#703688"
        />
      </div>
      <div class="blockWrap" @click="openEnrollInfoDailog">
        <span class="lockIcon"><i class="fas fa-lock" /></span>
        <Card
          title="MONTHLY REPORTS"
          icon="fas fa-calendar-alt"
          :state="String(reports.length)"
          stateText="reports"
          footerText="View reports"
          titleBackgroundColor="#f5ab36"
          contentBackgroundColor="#f39c12"
          footerBackgroundColor="#c87f0a"
        />
      </div>
      <router-link
        :to="{
          name: 'counselingUserApplication',
          params: { username: user.basic_info.username }
        }"
      >
        <Card
          title="APPLICATION FORMS"
          icon="fas fa-people-arrows"
          :state="String(applicationCount)"
          stateText=""
          footerText="View my applications"
          titleBackgroundColor="#ffe9b0"
          contentBackgroundColor="#FFE194"
          footerBackgroundColor="#ffcd4d"
        />
      </router-link>
      <router-link
        :to="{
          name: 'counselingUserTasks',
          params: { username: user.basic_info.username }
        }"
      >
        <Card
          title="TASKS"
          icon="fas fa-list"
          stateText=""
          footerText="View my tasks"
          titleBackgroundColor="#417D7A"
          contentBackgroundColor="#1D5C63"
          footerBackgroundColor="#1A3C40"
        />
      </router-link>
    </template>
    <el-dialog
      :visible.sync="isEnrollInfoVisible"
      width="60%"
      class="enrollInformation"
      center
    >
      <h5 slot="title" class="text-success">
        <i class="fas fa-star"></i> 報名 Ivy Way 升學輔導的課程，解鎖更多功能！
      </h5>
      <section class="text-center banner">
        <div class="inner">
          <el-button @click="() => $router.push({ name: 'QuickEnroll' })">
            <span>{{ $t("landingPage.enroll.btn") }}</span>
          </el-button>
        </div>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import { thirdAppTypeDict } from "@/class/ThirdApp";
import Card from "@/views/private/CounselingsState/Card";
import collectSchoolApi from "@/apis/collectSchool";
import profileApi from "@/apis/profile";
import essayApi from "@/apis/essay";
import StudentEssayStatuses from "@/views/private/Counseling/EssayComponets/StudentEssayStatuses";

export default {
  props: {
    meets: {
      type: Array,
      default: () => []
    },
    reports: {
      type: Array,
      default: () => []
    },
    isEnrolledCounseling: {
      type: Boolean,
      default: true
    },
    studentUserId: {
      type: Number,
      default: null
    },
    counselingId: {
      type: Number,
      default: null
    }
  },
  components: {
    Card
  },
  watch: {
    studentUserId() {
      this.initCounselingStates();
    }
  },
  computed: {
    meetHour() {
      return this.meets.reduce(
        (accumulator, { teaching_log }) =>
          Math.round((accumulator + teaching_log.full_hour) * 100) / 100,
        0
      );
    },
    essayPromptCount() {
      let applicationEssayPartCountMap = {};
      this.applicationEssays.forEach((applicationEssay) => {
        let application = "";
        Object.keys(thirdAppTypeDict).some(applicationType => {
          if (applicationEssay[`is_${applicationType}`]) {
            application = applicationType;
            return true;
          } else {
            return false;
          }
        });
        applicationEssayPartCountMap[
          `${application}_${applicationEssay.part}`
        ] = applicationEssay.part_min_essay;
      });
      const schoolEssayPartCountMap = {};
      this.schoolEssays
        .filter(schoolEssay =>
          this.collectedCounselingSchools.some(
            ({ school_id }) => school_id === schoolEssay.school_id
          )
        )
        .forEach(schoolEssay => {
          schoolEssayPartCountMap[
            `${schoolEssay.school_id}_${schoolEssay.part}`
          ] = schoolEssay.part_min_essay;
        });
      const demandEssayCountMap = {
        ...applicationEssayPartCountMap,
        ...schoolEssayPartCountMap
      };
      return Object.keys(demandEssayCountMap).reduce(
        (accumulation, essayPartKey) =>
          demandEssayCountMap[essayPartKey] + accumulation,
        0
      );
    },
    schoolEssays() {
      return this.essayPrompts
        .filter(essayPrompt => (
          Object.keys(thirdAppTypeDict).every((thirdApp) => (
            !essayPrompt[`is_${thirdApp}`]
          ))
        ));
    },
    schoolUsingApplications() {
      const applicationSet = new Set();
      this.collectedCounselingSchools.forEach((school) => {
        if (school.application_portal) {
          applicationSet.add(school.application_portal);
        }
      });
      return Array.from(applicationSet);
    },
    applicationEssays() {
      const applicationSet = new Set();
      this.schoolUsingApplications.forEach((thirdApplication) => {
        applicationSet.add(thirdApplication);
      });

      this.$store.getters["thirdApp/usingApplications"]
        .forEach((thirdApplication) => {
          applicationSet.add(thirdApplication);
        });
      const applicationsFlag = Array.from(applicationSet).map(
        application => `is_${application}`
      );
      return this.essayPrompts.filter((essayPrompt) => (
        applicationsFlag.some(applicationFlag => essayPrompt[applicationFlag])
      ));
    },
    completedEssayCount() {
      const createdEssayPartSet = new Set();
      Object.keys(thirdAppTypeDict).forEach(thirdApp => {
        this.essayPrompts
          .filter(essayPrompt => essayPrompt[`is_${thirdApp}`])
          .forEach(essayPrompt => {
            if (
              essayPrompt.student_essay &&
              essayPrompt.student_essay.status === StudentEssayStatuses.completed
            ) {
              createdEssayPartSet.add(`${thirdApp}_${essayPrompt.part}`);
            };
          });
      });

      const schoolEssaySet = new Set();
      this.schoolEssays.forEach((essayPrompt) => {
        if (
          essayPrompt.student_essay &&
          essayPrompt.student_essay.status === StudentEssayStatuses.completed
        ) {
          schoolEssaySet.add(`${essayPrompt.school_id}_${essayPrompt.part}`);
        };
      });
      return createdEssayPartSet.size + schoolEssaySet.size;
    },
    applicationsOfSchoolCanUsing() {
      const applicationSet = new Set();
      this.collectedCounselingSchools.forEach(({ school }) => {
        school.third_service_schools.forEach(({ service }) => {
          applicationSet.add(service);
        });
      });
      return Array.from(applicationSet);
    },
    applicationCount() {
      const applicationSets = new Set();
      [
        ...this.$store.getters["thirdApp/usingApplications"],
        ...this.applicationsOfSchoolCanUsing
      ].forEach(application => {
        applicationSets.add(application);
      });

      return applicationSets.size;
    }
  },
  data() {
    return {
      user: { basic_info: {} },
      collectedCounselingSchools: [],
      essayPrompts: [],
      isEnrollInfoVisible: false
    };
  },
  async created() {
    this.initCounselingStates();
  },
  methods: {
    async initCounselingStates() {
      if (this.studentUserId) {
        const user = await profileApi.getUserData(this.studentUserId);
        await this.$store.dispatch("thirdApp/getUsingApplications", this.studentUserId);
        this.user = user;
        this.fetchCollectedCounselingSchools();
        this.fetchEssayPrompts();
      }
    },
    async fetchCollectedCounselingSchools() {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: this.studentUserId,
        counselingSchoolsType: this.user.student.counseling_schools_type
      });
      this.collectedCounselingSchools = counseling_schools;
    },
    async fetchEssayPrompts() {
      const {
        essay_prompts: essayPrompts
      } = await essayApi.getThirdApplicationEssayPrompts(this.studentUserId);
      this.essayPrompts = essayPrompts;
    },
    openEnrollInfoDailog() {
      this.isEnrollInfoVisible = true;
    }
  }
};
</script>

<style scoped>
@import "./index.scss";

a {
  text-decoration: none;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
}
</style>
